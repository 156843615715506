::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  width: 99vw;
  height: 99vh;
  text-align: center;
  font-family: "Piazzolla", serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: x-large;
  overflow: hidden;
}

.cookie {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(39, 39, 39);
  color: white;
  z-index: 1;
  text-align: center;
  border-radius: 20px;
  opacity: 0.95;
  padding: 10px;
}

span {
  text-decoration: underline;
  cursor: pointer;
}

p {
  transition: all 0.8s;
  white-space: pre-wrap;
}

a {
  color: black;
  text-decoration: none;
  margin-bottom: 5px;
  transition: all 0.8s;
}

.menu-list a {
  margin-top: 1em;
}

.menu-list a:hover {
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.content {
  width: 69vw;
  height: 100vw;
  /* overflow-y: scroll; */
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  font-size: x-large;
  width: 30vw;
}

.bio-text {
  overflow-y: scroll;
  max-height: 80vh;
}

.text {
  overflow-y: scroll;
  max-height: 80vh;
}

.lang {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.lang p {
  padding: 5px;
  padding-left: 0px;
  cursor: pointer;
}

#ercan-img {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

#ercan1 {
  width: 262.84px;
  margin: -21.875% 0;
  object-fit: cover;
  z-index: -1;
  justify-self: flex-end;
}

.artikel h3 {
  margin-top: 1em;
  padding: 0px 10px;
}

.video-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.video-nav a {
  margin-right: 2em;
  margin-top: 1em;
}

.videos-container {
  overflow-y: scroll;
  max-height: 80vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
}

.video {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
  min-height: 270px;
}

.video p {
  width: 20vw;
  font-size: medium;
  /* max-height: 270px; */
  overflow-y: scroll;
  margin-top: 1.5em;
  margin-left: 2em;
}

iframe {
  border-radius: 20px;
  margin-top: 1em;
}

.footer {
  position: fixed;
  bottom: 0px;
  /* height: 2vh; */
  text-align: center;
  width: 99vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  z-index: 2;
}

.footer a {
  font-size: medium;
  color: gray;
}

.podcast-link {
  font-style: italic;
  text-decoration: underline;
}

@media only screen and (max-width: 1025px) {
  .App {
    font-size: small;
  }

  .menu {
    font-size: small;
  }

  .bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .artikel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .videos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 90vh;
  }

  iframe {
    width: 50vw;
    height: 29vw;
  }

  .video {
    margin-bottom: 1em;
    padding-bottom: 0px;
    min-height: 70vh;
    /* max-height: 90vh; */
    border-bottom: black 1px solid;
  }

  .video-text {
    /* border-bottom: black 1px solid; */
    overflow-y: auto;
  }

  .video a {
    display: block;
    /* margin-top: 1em; */
    position: relative;
  }

  a img {
    width: 50vw;
    height: auto;
    border-radius: 10px;
  }

  .play {
    position: absolute;
    width: 9vw;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.7;
  }

  .videos-container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .video {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* margin-top: 10px; */
  }

  .video p {
    width: 50vw;
    margin-left: 0px;
    overflow-y: scroll;
    font-size: small;
  }

  .footer * {
    font-size: smaller;
  }

  .text {
    max-height: 70vh;
  }

  #ercan1 {
    width: 30vw;
  }
}

@media only screen and (max-width: 1025px) and (orientation: landscape) {
  #ercan1 {
    object-fit: cover;
    height: 60vh;
    width: auto;
    /* object-position: 100% 50%; */
  }
}
